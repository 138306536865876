import React, { Fragment } from "react";

export default function ErrorPage(props) {
  return (
    <Fragment>
      <div>
        <h1>Error Page!</h1>
        <div>{props && props.errorMessage ? props.errorMessage : ""}</div>
      </div>
    </Fragment>
  );
}
