import axios from "axios";
import Bootstrap from "../configs/Bootstrap";

const getHttp = (uri, payload = {}) => {
  return new Promise((resolve, reject) => {
    const apiURL = `${Bootstrap.JOB_API_BASE_URL}${uri}`;
    const configs = {
      headers: { "content-type": "appilication/json" },
      params: payload,
    };
    axios
      .get(apiURL, configs)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};

const postHttp = (uri, payload = {}, headers = {}) => {
  return new Promise((resolve, reject) => {
    const apiURL = `${Bootstrap.JOB_API_BASE_URL}${uri}`;
    axios
      .post(apiURL, payload, headers)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};

export { getHttp, postHttp };
