import React, { Fragment } from "react";

export default function Header() {
  return (
    <Fragment>
      <nav className="navbar navbar-expand-lg navbar-light bg-dark">
        <div className="container-fluid">
          <img
            width="300px"
            src={`${process.env.PUBLIC_URL}/images/cselogo.png`}
            alt="logo"
          />
          
          <div className="menu d-flex">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navBurger"
              aria-controls="navBurger"
              // aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navBurger">
              <div className="navbar-nav">
                {/* <a
                  className="nav-link nav-item"
                  href={`${process.env.PUBLIC_URL}`}
                >
                  Home
                </a> */}
                <a
                  className="nav-link nav-item"
                  href="https://www.cseindia.org/page/aboutus"
                  target="_blank"
                  rel="noreferrer"
                >
                  About us
                </a>
                <a
                  className="nav-link nav-item"
                  href="https://www.cseindia.org/volunteer-1536"
                  target="_blank"
                  rel="noreferrer"
                >
                  Jobs
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </Fragment>
  );
}
