import React, { Fragment, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const userProfile = <Fragment>Admin</Fragment>;

  const logOut = () => {
    setTimeout(() => {
      navigate("/logout", { replace: true });
    }, 100);
  };

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken !== "true") {
      navigate("/login", { replace: true });
    }
  });


  return (
    <Fragment>
      <nav className="navbar navbar-expand-lg navbar-light bg-dark">
        <div className="container-fluid">
          <div className="pull-left">
            <a className="navbar-brand" href={`/admin/dashboard`}>
              <img
                src={`${process.env.PUBLIC_URL}/images/cselogo.png`}
                alt="CSE India"
                width={300}
              />
            </a>
          </div>
          <div className="pull-right">
            <button
              className="navbar-toggler position-static"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon bg-white"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    className="nav-link nav-item active"
                    to={`/admin/dashboard`}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link nav-item" to={`/admin/dashboard`}>
                    Job Applications
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link nav-item" to={`/admin/jobpost`}>
                    Job Posts
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link nav-item dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {userProfile}
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                    style={{ minWidth: '0', padding: '0' }}
                  >
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item logout-link"
                        onClick={logOut}
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item"></li>
              </ul>
              {/* <form className="d-flex">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button className="btn btn-outline-success" type="submit">
                Search
              </button>
            </form> */}
            </div>
          </div>
        </div>
      </nav>
    </Fragment>
  );
}
