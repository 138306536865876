import Header from "../common/Header";
import Footer from "../common/Footer";

export default function JobsLayout(props) {
  const { children } = props;
  return (
    <div className="fluid-container">
      <Header />
      <div className="content">{children}</div>
      <Footer />
    </div>
  );
}
