import React, { Fragment, useState } from "react";
import { useGoogleLogin } from "react-google-login";
import axios from "axios";
import Bootstrap from "../../configs/Bootstrap";
import { useNavigate } from "react-router-dom";
import JobsLayout from "../layouts/JobsLayout";

const clientId = Bootstrap.GOOGLE_SECRET_KEY;

export default function SignIn() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({ username: "", password: "" });

  const { username, password } = formData;

  const onSuccess = (result) => {
    setTimeout(() => {
      const isLogged = result.tokenId ?? "";
      if (isLogged) {
        localStorage.setItem("authToken", true);
        navigate("/admin/dashboard", { replace: true });
      }
    }, 100);
  };

  const onFailure = (result) => {
    setTimeout(() => {
      console.log("Login failed: result:", result);
      navigate("/login", { replace: true });
    }, 100);
  };

  const { signIn } = useGoogleLogin({
    clientId,
    onSuccess,
    onFailure,
    isSignedIn: true,
    accessType: "offline",
    // responseType: 'code',
    prompt: "consent",
  });

  const changeHandler = e => {
    e.preventDefault();
    if (e.target.value) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setError('Validation Error!');
    }
  }

  const customSignIn = (e) => {
    e.preventDefault();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    if (username && password) {
      axios
        .post(`${Bootstrap.JOB_API_BASE_URL}/login`, formData, config)
        .then((response) => {
          if (response.data.resultCode == "200") {
            localStorage.setItem("authToken", true);
            navigate("/admin/dashboard", { replace: true });
          } else {
            setError('Login Failed!, Please Contact Technical Support Team!');
          }
        })
        .catch((error) => {
          console.log("server error", error);
          setError('Something went wrong!, Please Contact Technical Support Team!');
        });
    } else {
      setError('Please enter the required username and password!');
    }
  }

  return (
    <Fragment>
      <JobsLayout>
        <div className="login">
          <h1>Login - Job Portal Admin</h1>
          <hr />
          <section className="">
            <div className="px-4 py-5 px-md-5 text-center text-lg-start" >
              <div className="container">
                <div className="row gx-lg-5 align-items-center">
                  <div className="col-lg-6 mb-5 mb-lg-0">
                    <h1 className="display-3 fw-bold ls-tight">
                      Login <br />
                      <span className="text-primary">Job Portal</span>
                    </h1>
                    <p >
                      For any technical assistance - <br /><br />
                      Please write to <b>Technical Support Team!</b>
                      <br />OR<br />
                      Please write to <a href="mailto:dpriyanka@cseindia.org"><b>dpriyanka@cseindia.org</b></a>
                    </p>
                  </div>

                  <div className="col-lg-6 mb-5 mb-lg-0">
                    <div className="card">
                      <div className="card-body py-5 px-md-5">
                        <form onSubmit={customSignIn}>
                          {error && <div className="form-outline mb-4">
                            <p className="alert alert-danger" role="alert">{error}
                              <br /><small className="text-muted"><u>Hint:</u> Please check once your login username and password! </small>
                            </p>
                          </div>}

                          <div className="form-outline mb-4">
                            <input
                              type="text"
                              name="username"
                              className="form-control"
                              value={username}
                              onChange={changeHandler}
                              placeholder="Please enter username"
                            />
                          </div>

                          <div className="form-outline mb-4">
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              value={password}
                              onChange={changeHandler}
                              placeholder="Please enter password"
                            />
                          </div>

                          <div className="text-center">
                            <input type="submit" name="submit" value="Sign In" className="btn btn-primary btn-block mb-4" />
                          </div>

                          <div className="text-center">
                            <p>OR</p>

                            <button type="submit" onClick={signIn}>
                              <img
                                src="images/GoogleSignInBtn.png"
                                alt="SignIn with Google"
                                className="responsive"
                              />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </JobsLayout>
    </Fragment>
  );
}
