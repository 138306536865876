import React, { Fragment } from "react";
import { useField } from "formik";

export default function TextAreaField(props) {
  const [field, meta] = useField(props);
  const errclass = meta.touched && meta.error ? "is-invalid" : "";
  return (
    <Fragment>
      <div className="form-group">
        <textarea
          type="text"
          {...field}
          {...props}
          className={`form-control input-md ${errclass}`}
          rows="5"
        />
      </div>
      <span className="help-block text-danger">
        {meta.touched && meta.error}
      </span>
    </Fragment>
  );
}
