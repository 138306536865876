import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { at } from "lodash";
import { useField } from "formik";

function SelectField(props) {
  const { label, data, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error ? true : false;

  function _renderHelperText() {
    if (isError) {
      return <span className="help-block text-danger">{error}</span>;
    }
  }

  return (
    <Fragment>
      <div className="form-group">
        {/* <label>{label}</label> */}
        <select
          {...field}
          value={selectedValue ? selectedValue : ""}
          className="form-select"
          {...rest}
          // error={isError}
        >
          {data.map((element, index) => (
            <option key={index} value={element.value}>
              {element.label}
            </option>
          ))}
        </select>
        {_renderHelperText()}
      </div>
    </Fragment>
  );
}

SelectField.defaultProps = {
  data: [],
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SelectField;
