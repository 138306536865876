import React, { Fragment, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import JobsLayout from "../layouts/JobsLayout";
import Bootstrap from "../../configs/Bootstrap";
import { getHttp } from "../../utilities/Actions";

function ViewDetails() {
  let renderHTML;
  const { key, type, field } = useParams();
  const [dataView, setDataView] = useState({});
  useEffect(() => {
    getHttp(`/app-detail?id=${key}`, {})
      .then((response) => {
        setDataView(response.resultDetail);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handlePrint = (e) => {
    e.preventDefault();
    var css = "@page { size: landscape; }",
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
    head.appendChild(style);
    window.print();
  };

  const previewLinkURL = `${Bootstrap.API_BASE_URL}/api/preview?key=${key}&type=${type}`;
  const previewLinkArray = ["uploadFile", "uploadOtherFile"];
  if (field && previewLinkArray.includes(field)) {
    renderHTML = (
      <Fragment>
        <div className="row">
          <div className="col-sm-6">
            <h4>Job Application Details</h4>
          </div>
          {/* <div className="col-sm-6">
            <Link
              to={-1}
              className="btn btn-primary btn-small mb-1"
              style={{ float: "right" }}
            >
              Back
            </Link>
          </div> */}
        </div>
        <iframe src={previewLinkURL} height={950} width={`100%`} />
      </Fragment>
    );
  } else {
    if (dataView && Object.keys(dataView).length > 0) {
      renderHTML = (
        <Fragment>
          <div className="row">
            <div className="col-sm-6">
              <h4>Job Application Details</h4>
            </div>
            <div className="col-sm-6">
              <Link
                to={-1}
                className="btn btn-primary btn-small mx-2"
                style={{ float: "right" }}
              >
                Back
              </Link>
              <button
                onClick={handlePrint}
                className="btn btn-success"
                style={{ float: "right" }}
              >
                Print
              </button>
            </div>
          </div>
          <hr />
          <div className="row mb-3">
            <div className="col-sm-6">
              <img
                width="150px"
                src={`${process.env.PUBLIC_URL}/images/logo.jpg`}
                alt="logo"
              />
            </div>
            <div className="col-sm-6">
              <h5>Centre for Science and Environment</h5>
              <p>41, Tuglakabad Institutional Area, New Delhi-110062</p>
            </div>
          </div>
          <table className="table table-hover table-responsive review-table">
            <thead>
              <tr>
                <th scope="col">Label</th>
                <th scope="col">Value</th>
              </tr>
            </thead>
            <tbody>
              {dataView &&
                Object.keys(dataView).map((element, index) => (
                  <tr key={index}>
                    <td>{element}</td>
                    <td>{dataView[element]}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Fragment>
      );
    }
  }

  return (
    <Fragment>
      <JobsLayout>{renderHTML}</JobsLayout>
    </Fragment>
  );
}

export default ViewDetails;
