import * as Yup from "yup";
import parse from "date-fns/parse";
import formSchemaFields from "./formSchemaFields";
const {
  formField: {
    jobPostType,
    fullName,
    gender,
    contactNo,
    emailId,
    dob,
    maritalStatus,
    familyBackground,
    firstHighestQualification,
    secondHighestQualification,
    otherQualification,
    totalExperience,
    currentOrganisation,
    currentLocation,
    preferredLocation,
    currentSalary,
    expectedSalary,
    currency,
    noticePeriod,
    currentDependent,
    earlierWokedWithUs,
    currentOpeningSource,
    coverLetter,
  },
} = formSchemaFields;

export default [
  Yup.object().shape({
    [jobPostType.name]: Yup.array().required(`${jobPostType.requiredErrorMsg}`),
    [fullName.name]: Yup.string().required(`${fullName.requiredErrorMsg}`),
    [gender.name]: Yup.string().required(`${gender.requiredErrorMsg}`),
    [contactNo.name]: Yup.string().required(`${contactNo.requiredErrorMsg}`),
    [emailId.name]: Yup.string()
      .email("Invalid email, please enter a valid email!")
      .max(255)
      .required(`${emailId.requiredErrorMsg}`),
    [dob.name]: Yup.string().required(`${dob.requiredErrorMsg}`),
    // [dob.name]: Yup.date()
    //   .transform(function (value, originalValue) {
    //     if (this.isType(value)) {
    //       return value;
    //     }
    //     const result = parse(originalValue, "DD/MM/YYYY", new Date());
    //     return result;
    //   })
    //   .typeError("Please enter a valid date!")
    //   .required(`${dob.requiredErrorMsg}`)
    //   .min("1970-01-01", "Date is too early"),
    [maritalStatus.name]: Yup.string().required(
      `${maritalStatus.requiredErrorMsg}`
    ),
  }),
  Yup.object().shape({
    [firstHighestQualification.name]: Yup.string().required(
      `${firstHighestQualification.requiredErrorMsg}`
    ),
    [secondHighestQualification.name]: Yup.string().required(
      `${secondHighestQualification.requiredErrorMsg}`
    ),
  }),
  Yup.object().shape({
    [totalExperience.name]: Yup.string().required(
      `${totalExperience.requiredErrorMsg}`
    ),
    [currentOrganisation.name]: Yup.string().required(
      `${currentOrganisation.requiredErrorMsg}`
    ),
    [currentLocation.name]: Yup.string().required(
      `${currentLocation.requiredErrorMsg}`
    ),
    [preferredLocation.name]: Yup.string().required(
      `${preferredLocation.requiredErrorMsg}`
    ),
    [currentSalary.name]: Yup.string().required(
      `${currentSalary.requiredErrorMsg}`
    ),
    [expectedSalary.name]: Yup.string().required(
      `${expectedSalary.requiredErrorMsg}`
    ),
  }),
];
