import React, { Fragment, useRef, useEffect } from "react";
import { useGoogleLogout } from "react-google-login";
import { useNavigate } from "react-router-dom";
import Bootstrap from "../../configs/Bootstrap";
const clientId = Bootstrap.GOOGLE_SECRET_KEY;

export default function SignOut() {
  const ref = useRef(null);
  const navigate = useNavigate();

  const onLogoutSuccess = () => {
    localStorage.setItem("authToken", "");
    navigate("/login", { replace: true });
  };

  const onFailure = (err) => {
    console.log("Logout Error", err);
    navigate("/login", { replace: true });
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  useEffect(() => {
    setTimeout(() => {
      ref.current.click();
    }, 100);
  }, []);

  return (
    <Fragment>
      <button
        ref={ref}
        type="submit"
        onClick={signOut}
        style={{ display: "none" }}
      >
        Logging out!, Please wait...!!
      </button>
    </Fragment>
  );
}
