import React, { useState, useEffect } from "react";
import { Field, useField } from "formik";
import { UploadField } from "../formFields";

export default function ResumeDetails(props) {
  const {
    formField: { uploadFile, uploadOtherFile },
  } = props;

  //Resume upload
  const [field, meta, helper] = useField(uploadFile.name);
  const { touched, error } = meta;
  const isError = touched && error;
  const { setValue } = helper;
  const { value } = field;
  const [fileName, setFileName] = useState(value.name);
  const [file, setFile] = useState(value.file);
  const [src, setSrc] = useState(value.src);

  const _onChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => setFileName(file.name);
      reader.readAsDataURL(file);
      setSrc(reader);
      setFile(file);
    }
  };

  useEffect(() => {
    if (file && fileName && src) {
      setValue({ file: file, src: src, name: fileName });
      console.log("useEffect Called", fileName, src, file);
    }
  }, [src, fileName, file]);

  //Other upload
  const [field1, meta1, helper1] = useField(uploadOtherFile.name);
  const { touched: touched1, error: error1 } = meta;
  const isError1 = touched1 && error1;
  const { setValue: setValue1 } = helper1;
  const { value: value1 } = field1;
  const [fileName1, setFileName1] = useState(value1.name);
  const [file1, setFile1] = useState(value1.file);
  const [src1, setSrc1] = useState(value1.src);

  const _onChange1 = (e) => {
    let reader1 = new FileReader();
    let file1 = e.target.files[0];
    if (file1) {
      reader1.onloadend = () => setFileName1(file1.name);
      reader1.readAsDataURL(file1);
      setSrc1(reader1);
      setFile1(file1);
    }
  };

  useEffect(() => {
    if (file1 && fileName1 && src1) {
      setValue1({ file: file1, src: src1, name: fileName1 });
      console.log("useEffect Called1", fileName1, src1, file1);
    }
  }, [src1, fileName1, file1]);

  return (
    <React.Fragment>
      <h4>Resume Details</h4>
      <hr />
      <div className="row mb-3">
        <div className="col">
          <label
            className="form-label"
            style={{ color: `${isError ? "red" : "var(--main-color)"}` }}
          >
            {uploadFile.label}
          </label>
          <Field
            variant="outlined"
            field={field}
            component={UploadField}
            onChange={_onChange}
            isError={isError}
            className="form-control"
          />
          {/* {isError && <FormHelperText color={"red"}>{error}</FormHelperText>} */}
        </div>
        {/* <div>{file && src && <img file={file} src={src} />}</div> */}
      </div>

      <div className="row mb-3">
        <div className="col">
          <label
            className="form-label"
            style={{ color: `${isError1 ? "red" : "var(--main-color)"}` }}
          >
            {uploadOtherFile.label}
          </label>
          <Field
            variant="outlined"
            field={field1}
            component={UploadField}
            onChange={_onChange1}
            isError={isError1}
            className="form-control"
          />
          {/* {isError && <FormHelperText color={"red"}>{error}</FormHelperText>} */}
        </div>
        {/* <div>{file && src && <img file={file} src={src} />}</div> */}
      </div>
    </React.Fragment>
  );
}
