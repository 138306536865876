import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import Select from "react-select";
import RenderTable from "./RenderTable";
import AdminLayout from "../layouts/AdminLayout";
import { getHttp } from "../../utilities/Actions";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  tableHeadValue,
  LinkComponent,
  getBooleanValue,
} from "../../utilities/Helper";

const Dashboard = () => {
  let initialState = [];
  let postListOptions = [];
  useEffect(() => {
    getHttp(`/post-list?status_admin=enabled`, {})
      .then((response) => {
        postListOptions.push({ label: "All", value: "all" });
        response.resultList.map((item) => {
          postListOptions.push({ label: `${item.post}-${item.id}`, value: `${item.post}-${item.id}` });
        });
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      });
  });

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState(initialState);
  const [selectedOption, setSelectedOption] = useState("");
  const [checkboxsVal, setCheckboxsVal] = useState([]);
  const [checked, setChecked] = useState(false);

  const gridRef = useRef(null);
  const [tableHead] = useState(tableHeadValue);

  useEffect(() => {
    const { value: selectValue } = selectedOption;
    const postType = selectValue && selectValue != "all" ? selectValue : "";
    getHttp(`/app-list?type=${postType}`, {})
      .then((response) => {
        setDataList(response.resultList);
        setIsLoading(false);
      })
      .catch(() => {
        setError(true);
        setIsLoading(true);
      });
  }, [selectedOption]);

  const handleCSVExport = useCallback(() => {
    const params = {
      onlySelected: getBooleanValue("#onlySelected"),
      fileName: `CSV-${moment().format("DD/MM/YYYY")}`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const handleExcelExport = () => {
    const selectedItemsVal = [];
    const nodes = gridRef.current.api.getSelectedNodes();
    const selectedData = nodes.map((node) => node.data);
    selectedData.map((node) => selectedItemsVal.push(node.id));
    setCheckboxsVal(selectedItemsVal);
    setChecked(!checked);
  };

  const handleExportCheckboxBtn = () => {
    setChecked(!checked);
  };

  let renderJobAppsListTable;
  if (dataList) {
    renderJobAppsListTable = (
      <Fragment>
        <div className="jobappslist">
          <div
            className="ag-theme-alpine"
            style={{ height: 550, width: "100%" }}
          >
            <div className="row">
              <div className="col-lg-5">
                <input
                  className="form-check-input mt-2 mx-1"
                  type="checkbox"
                  id="onlySelected"
                  checked={checked}
                  onChange={handleExcelExport}
                />
                <label className="form-check-label" htmlFor="onlySelected">
                  Only selected record(s)
                </label>
                <button
                  onClick={handleCSVExport}
                  className="btn btn-primary btn-sm mx-3"
                >
                  Download CSV
                </button>
                <div
                  className="btn btn-primary btn-sm mx-3"
                  onClick={handleExportCheckboxBtn}
                >
                  <ReactHTMLTableToExcel
                    id="jobAppExcel"
                    className="btn btn-primary btn-sm padding0px"
                    table="jobAppTableId"
                    sheet="jobAppListSheet"
                    filename={`Excel-${moment().format("DD/MM/YYYY")}`}
                    buttonText="Download Excel"
                  />
                </div>
              </div>
              <div className="col-lg-3">
                {postListOptions ? (
                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={postListOptions}
                    className="form-group mb-3"
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <AgGridReact
              rowData={dataList}
              columnDefs={tableHead}
              frameworkComponents={{ LinkComponent }}
              ref={gridRef}
              rowSelection="multiple"
              pagination={true}
              paginationPageSize={10}
            ></AgGridReact>
          </div>
        </div>
      </Fragment>
    );
  }

  if (error) {
    return (
      <Fragment>
        <AdminLayout>
          <div className="jobappslist">
            <div className="text-center">
              <p> Whoops!, something went wrong, please try later!</p>
            </div>
          </div>
        </AdminLayout>
      </Fragment>
    );
  } else if (isLoading) {
    return (
      <Fragment>
        <AdminLayout>
          <div className="jobappslist">
            <div className="text-center">
              <p> Loading, please wait...</p>
            </div>
          </div>
        </AdminLayout>
      </Fragment>
    );
  } else if (dataList) {
    return (
      <Fragment>
        <AdminLayout>
          <h6>Job Applications</h6>
          {renderJobAppsListTable}
          <RenderTable dataList={dataList} selectedItemsArray={checkboxsVal} />
          <hr />
        </AdminLayout>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <AdminLayout>
          <div className="jobappslist">
            <div className="text-center">
              <p> No data found!</p>
            </div>
          </div>
        </AdminLayout>
      </Fragment>
    );
  }
};

export default Dashboard;
