import React, { Fragment } from "react";
import { useField } from "formik";

export default function RadioField(props) {
  const [field, meta] = useField(props);
  const errclass = meta.touched && meta.error ? "is-invalid" : "";
  return (
    <Fragment>
      <div className="form-check form-check-inline">
        <input
          type="radio"
          {...field}
          {...props}
          className="form-check-input"
        />{" "}
        &nbsp;{props.label} &nbsp;
        <span className="help-block text-danger">
          {meta.touched && meta.error}
        </span>
      </div>
    </Fragment>
  );
}
