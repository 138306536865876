import React from "react";
import { InputField, TextAreaField } from "../formFields";

export default function QualificationDetails(props) {
  const {
    formField: {
      firstHighestQualification,
      secondHighestQualification,
      otherQualification,
    },
  } = props;

  return (
    <React.Fragment>
      <h4>Qualification Details</h4> <hr />
      <div className="row mb-3">
        <div className="col">
          <InputField
            name={firstHighestQualification.name}
            label={firstHighestQualification.label}
            placeholder={firstHighestQualification.label}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <InputField
            name={secondHighestQualification.name}
            label={secondHighestQualification.label}
            placeholder={secondHighestQualification.label}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <TextAreaField
            name={otherQualification.name}
            label={otherQualification.label}
            placeholder={otherQualification.label}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
