import React, { Fragment } from "react";
import ErrorPage from "./ErrorPage";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: "" };
  }

  logErrorToMyService = (error, info) => {
    // console.log(error, info);
    // You can also log the error to an error reporting service
  };

  static getDerivedStateFromError(error) {
    console.log("getDerivedStateFromError");
    return { hasError: true, errorMessage: error.toString() };
    // this.setState({ hasError: true, errorMessage: error });
  }

  componentDidCatch(error, info) {
    this.logErrorToMyService(error, info);
  }

  render() {
    const { error, errorMessage } = this.state;
    if (this.state.hasError) {
      return (
        <Fragment>
          <ErrorPage {...{ error, errorMessage }} />
        </Fragment>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
