export default {
  formId: "jobsForm",
  formField: {
    jobPostType: {
      name: "jobPostType",
      label: "Post applying for*",
      requiredErrorMsg: "*",
    },
    fullName: {
      name: "fullName",
      label: "Full Name*",
      requiredErrorMsg: "Full name is required",
    },
    gender: {
      name: "gender",
      label: "Gender*",
      requiredErrorMsg: "Gender is required",
    },
    contactNo: {
      name: "contactNo",
      label: "Contact No*",
      requiredErrorMsg: "Contact number is required",
    },
    emailId: {
      name: "emailId",
      label: "Email Id*",
      requiredErrorMsg: "Email address is required",
    },
    dob: {
      name: "dob",
      label: "Date of Birth (DD/MM/YYYY)*",
      requiredErrorMsg: "Date of Birth is required",
    },
    maritalStatus: {
      name: "maritalStatus",
      label: "Marital Status*",
      requiredErrorMsg: "Marital status is required",
    },
    familyBackground: {
      name: "familyBackground",
      label: "Family Background",
      requiredErrorMsg: "Family background is required",
    },

    firstHighestQualification: {
      name: "firstHighestQualification",
      label: "First highest educational qualification*",
      requiredErrorMsg: "First highest qualification is required",
    },
    secondHighestQualification: {
      name: "secondHighestQualification",
      label: "Second highest educational qualification*",
      requiredErrorMsg: "Second highest qualification is required",
    },
    otherQualification: {
      name: "otherQualification",
      label: "Other educational qualification (if any)",
      requiredErrorMsg: "Other qualification is required",
    },

    totalExperience: {
      name: "totalExperience",
      label: "Total experience*",
      requiredErrorMsg: "This is required",
    },
    currentOrganisation: {
      name: "currentOrganisation",
      label: "Current organisation*",
      requiredErrorMsg: "This is required",
    },
    currentLocation: {
      name: "currentLocation",
      label: "Current location*",
      requiredErrorMsg: "This is required",
    },
    preferredLocation: {
      name: "preferredLocation",
      label: "Preferred location*",
      requiredErrorMsg: "This is required",
    },
    currentSalary: {
      name: "currentSalary",
      label: "Current salary*",
      requiredErrorMsg: "This is required",
    },
    expectedSalary: {
      name: "expectedSalary",
      label: "Expected salary*",
      requiredErrorMsg: "This is required",
    },
    currency: {
      name: "currency",
      label: "Currency",
      requiredErrorMsg: "This is required",
    },
    noticePeriod: {
      name: "noticePeriod",
      label: "Notice period (if any)",
      requiredErrorMsg: "This is required",
    },
    currentDependent: {
      name: "currentDependent",
      label: "Current dependants (if any)",
      requiredErrorMsg: "This is required",
    },
    earlierWokedWithUs: {
      name: "earlierWokedWithUs",
      label: "Have you earlier woked with CSE?*",
      requiredErrorMsg: "This is required",
    },
    currentOpeningSource: {
      name: "currentOpeningSource",
      label: "How did you know about the vacancy*",
      requiredErrorMsg: "This is required",
    },
    currentOpeningSourceOther: {
      name: "currentOpeningSourceOther",
      label: "Please enter other details",
      requiredErrorMsg: "This is required",
    },
    coverLetter: {
      name: "coverLetter",
      label: "Cover letter (optional)",
      requiredErrorMsg: "This is required",
    },
    uploadFile: {
      name: "uploadFile",
      label: "Upload your resume (.pdf/.doc/docx)",
      requiredErrorMsg: "This is required",
    },
    uploadOtherFile: {
      name: "uploadOtherFile",
      label: "Upload other file (.pdf/.doc/docx)",
      requiredErrorMsg: "This is optional",
    },
  },
};
