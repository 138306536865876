import React, { useEffect, Fragment } from "react";
import Bootstrap from "../../../configs/Bootstrap";

export default function FinalMessage() {
  useEffect(() => {
    setTimeout(() => {
      // window.location.reload();
    }, 2500);
  }, []);

  return (
    <Fragment>
      <hr />
      <div className="row" style={{ textAlign: "center", minHeight: "400px" }}>
        <div className="col">
          <h5>Thank you for your application.</h5><br/>
          <p>
            <b>We will contact the shortlisted candidates within few weeks of the closing date.</b><br />
            Due to the volume of applications we are unfortunately not able to contact candidates who have not been shortlisted.
          </p>
          <p>
            Thank you for your interest in Centre for Science and Environment's work.<br />
            To keep up to date with what we do and any future vacancies please sign up to our newsletter <a href={Bootstrap.NEWSLETTER_URL} target="_blank">here</a>.
          </p>
          <p><a href={Bootstrap.APP_BASE_URL}>Go to Home</a></p>

          {/* <img src={`${process.env.PUBLIC_URL}/images/loader.GIF`} />
          <p>
            Please wait, while we are processing and submitting your request!<br />
            You will be redirected to home page in few seconds!
          </p> */}
        </div>
      </div>
    </Fragment>
  );
}
