import React from "react";

export default function Footer() {
  return (
    <></>
    // <div style={{ backgroundColor: "#000", minHeight: "25px" }}>
    //     {"Copyright © "}
    //     <a color="inherit" href="https://cseindia.org/">
    //       CSE India
    //     </a>
    //     <span> {new Date().getFullYear()}</span>
    // </div>
  );
}
