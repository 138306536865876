import React from "react";
import { InputField, SelectField } from "../formFields";

export default function WorkDetails(props) {
  const {
    formField: {
      totalExperience,
      currentOrganisation,
      currentLocation,
      preferredLocation,
      currentSalary,
      expectedSalary,
      currency,
      noticePeriod,
    },
  } = props;

  const currencyList = [
    {
      value: "INR",
      label: "INR",
    },
    {
      value: "USD",
      label: "USD",
    },
    {
      value: "OTHER",
      label: "Any other currency",
    },
  ];

  return (
    <React.Fragment>
      <h4>Work Details</h4> <hr/>
      <div className="row mb-3">
        <div className="col">
          <InputField
            name={totalExperience.name}
            label={totalExperience.label}
            placeholder={totalExperience.label}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <InputField
            name={currentOrganisation.name}
            label={currentOrganisation.label}
            placeholder={currentOrganisation.label}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <InputField
            name={currentLocation.name}
            label={currentLocation.label}
            placeholder={currentLocation.label}
          />
        </div>
        <div className="col">
          <InputField
            name={preferredLocation.name}
            label={preferredLocation.label}
            placeholder={preferredLocation.label}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          <InputField
            name={currentSalary.name}
            label={currentSalary.label}
            placeholder={currentSalary.label}
          />
        </div>
        <div className="col">
          <InputField
            name={expectedSalary.name}
            label={expectedSalary.label}
            placeholder={expectedSalary.label}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          <SelectField
            name={currency.name}
            label={currency.label}
            data={currencyList}
          />
        </div>
        <div className="col">
          <InputField
            name={noticePeriod.name}
            label={noticePeriod.label}
            placeholder={noticePeriod.label}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
