import React, { Fragment, useState } from "react";
import { InputField, RadioField, TextAreaField } from "../formFields";

export default function OtherDetails(props) {
  const {
    formField: {
      currentDependent,
      earlierWokedWithUs,
      currentOpeningSource,
      currentOpeningSourceOther,
      coverLetter,
    },
  } = props;

  const earlierWokedWithUsList = [
    {
      value: "YES",
      label: "YES",
    },
    {
      value: "NO",
      label: "NO",
    },
  ];

  const currentOpeningSourceList = [
    {
      value: "DEVNET",
      label: "DEVNET",
    },
    {
      value: "CSE WEBSITE",
      label: "CSE WEBSITE",
    },
    {
      value: "OTHER",
      label: "OTHER",
    },
  ];

  const [otherTextValue, setotherTextValue] = useState(false);

  const _onRadioClick = (e) => {
    e.target.value === "OTHER"
      ? setotherTextValue(true)
      : setotherTextValue(false);
  };

  return (
    <React.Fragment>
      <h4>Other Details</h4> <hr />
      <div className="row mb-3">
        <div className="col">
          <InputField
            name={currentDependent.name}
            label={currentDependent.label}
            placeholder={currentDependent.label}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label className="form-check-label">{earlierWokedWithUs.label}</label>
          <div className="form-check">
            {earlierWokedWithUsList.map((element, index) => (
              <Fragment key={index}>
                <RadioField
                  name={earlierWokedWithUs.name}
                  value={element.value}
                  label={element.label}
                />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label className="form-check-label">
            {currentOpeningSource.label}
          </label>
          <div className="form-check">
            {currentOpeningSourceList.map((element, index) => (
              <Fragment key={index}>
                <RadioField
                  name={currentOpeningSource.name}
                  value={element.value}
                  label={element.label}
                  onClick={_onRadioClick}
                />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      {otherTextValue ? (
        <div className="row mb-3">
          <div className="col">
            <InputField
              name={currentOpeningSourceOther.name}
              label={currentOpeningSourceOther.label}
              placeholder={currentOpeningSourceOther.label}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="row mb-3">
        <div className="col">
          <TextAreaField
            name={coverLetter.name}
            label={coverLetter.label}
            placeholder={coverLetter.label}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
