import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import Steps from "../common/Stepper/Steps";
import PersonalDetails from "./formSteps/PersonalDetails";
import QualificationDetails from "./formSteps/QualificationDetails";
import WorkDetails from "./formSteps/WorkDetails";
import OtherDetails from "./formSteps/OtherDetails";
import ResumeDetails from "./formSteps/ResumeDetails";
import ReviewDetails from "./formSteps/ReviewDetails";
import FinalMessage from "./formSteps/FinalMessage";
import validationSchema from "./formModels/validationSchema";
import formSchemaFields from "./formModels/formSchemaFields";
import formInitialValues from "./formModels/formInitialValues";
import Bootstrap from "../../configs/Bootstrap";
import axios from "axios";
import { format } from "date-fns";

const steps = [
  "Personal",
  "Qualification",
  "Work",
  "Others",
  "Resume",
  "Review",
];
const { formId, formField } = formSchemaFields;

function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <PersonalDetails formField={formField} />;
    case 1:
      return <QualificationDetails formField={formField} />;
    case 2:
      return <WorkDetails formField={formField} />;
    case 3:
      return <OtherDetails formField={formField} />;
    case 4:
      return <ResumeDetails formField={formField} />;
    case 5:
      return <ReviewDetails />;
    default:
      return <>{`Not Found`}</>;
  }
}

export default function ApplicationForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    await _sleep(100);
    if (values) {
      if (values.dob) {
        values.dob = format(values.dob, "dd/MM/yyyy");
      }
      const formData = new FormData();
      formData.append("formInputs", JSON.stringify(values));
      if (values && values.uploadFile.name) {
        formData.append(
          "resume",
          values.uploadFile.file,
          values.uploadFile.name
        );
      }
      if (values && values.uploadOtherFile.name) {
        formData.append(
          "resumeother",
          values.uploadOtherFile.file,
          values.uploadOtherFile.name
        );
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(`${Bootstrap.JOB_API_BASE_URL}/app-create`, formData, config)
        .then(() => {
          // console.log("Sent!");
        })
        .catch(() => {
          // console.log("Error!");
        });
    }
    actions.setSubmitting(false);
    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <Fragment>
      <div className="text-center">
        <h2>Application Form</h2>
      </div>
      {/* <RenderTitle title="Application Form" > */}

      <Steps activeStep={activeStep} steps={steps} />
      <React.Fragment>
        {activeStep === steps.length ? (
          <FinalMessage />
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting, errors }) => (
              <div className="form">
                <Form id={formId}>
                  {_renderStepContent(activeStep)}

                  <div className="text-center flex">
                    {activeStep !== 0 && (
                      <div className="btn-group">
                        <button
                          type="button"
                          onClick={_handleBack}
                          className="btn btn-secondary"
                        >
                          Back
                        </button>
                      </div>
                    )}
                    &nbsp;
                    <div className="btn-group">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn btn-primary"
                      >
                        {isLastStep ? "Submit" : "Save & Continue"}
                      </button>
                    </div>
                    <p className="small text-danger mt-3">
                      {(errors && Object.keys(errors).length > 0 ? (
                        <>
                          Please fill up all the mandatory(*) fields!
                        </>
                      ) : (<></>))}
                    </p>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        )}
      </React.Fragment>
    </Fragment>
  );
}
