import React, { Fragment } from "react";
import { useField } from "formik";

export default function CheckboxField(props) {
  const { label, value, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;

  const { name, value: formikValue } = field;
  // const { setFieldValue } = form;
  let values = formikValue || [];
  const _onChange = (e) => {
    if (values.includes(e.target.value)) {
      const selectedItems = values.filter((item) => item !== e.target.value);
      values = selectedItems;
    } else {
      values.push(e.target.value);
    }
    setValue(values);
  };

  return (
    <Fragment>
      <div className="form-check form-check-inline">
        <input
          type="checkbox"
          {...field}
          {...rest}
          value={value}
          checked={field.checked}
          onChange={_onChange}
          className="form-check-input"
        />
        &nbsp;{label}&nbsp;
        <span className="help-block text-danger">
          {meta.touched && meta.error}
        </span>
      </div>
    </Fragment>
  );
}
