import formSchemaFields from "./formSchemaFields";
const {
  formField: {
    jobPostType,
    fullName,
    gender,
    contactNo,
    emailId,
    dob,
    maritalStatus,
    familyBackground,
    firstHighestQualification,
    secondHighestQualification,
    otherQualification,
    totalExperience,
    currentOrganisation,
    currentLocation,
    preferredLocation,
    currentSalary,
    expectedSalary,
    currency,
    noticePeriod,
    currentDependent,
    earlierWokedWithUs,
    currentOpeningSource,
    currentOpeningSourceOther,
    coverLetter,
    uploadFile,
    uploadOtherFile
  },
} = formSchemaFields;

export default {
  [jobPostType.name]: [],
  [fullName.name]: "",
  [gender.name]: "",
  [contactNo.name]: "",
  [emailId.name]: "",
  [dob.name]: "",
  [maritalStatus.name]: "",
  [familyBackground.name]: "",
  [firstHighestQualification.name]: "",
  [secondHighestQualification.name]: "",
  [otherQualification.name]: "",
  [totalExperience.name]: "",
  [currentOrganisation.name]: "",
  [currentLocation.name]: "",
  [preferredLocation.name]: "",
  [currentSalary.name]: "",
  [expectedSalary.name]: "",
  [currency.name]: "",
  [noticePeriod.name]: "",
  [currentDependent.name]: "",
  [earlierWokedWithUs.name]: "",
  [currentOpeningSource.name]: "",
  [currentOpeningSourceOther.name]: "",
  [coverLetter.name]: "",
  [uploadFile.name]: "",
  [uploadOtherFile.name]: "",
};
