const base64Encode = (str) => {
  return btoa(str);
};

const base64Decode = (str) => {
  return atob(str);
};

const tableHeadValue = () => {
  return [
    {
      field: "id",
      sortable: true,
      filter: true,
      width: "150px",
      cellRenderer: "LinkComponent",
      checkboxSelection: true,
    },
    {
      headerName: "Resume (Attachment)", //custom name
      field: "uploadFile",
      cellRenderer: "LinkComponent",
      width: "350px",
    },
    {
      headerName: "Resume (Other)", //custom name
      field: "uploadOtherFile",
      cellRenderer: "LinkComponent",
      width: "350px",
    },
    { field: "fullName", sortable: true, filter: true },
    { field: "totalExperience", sortable: true, filter: true },
    {
      field: "firstHighestQualification",
      headerName: "Highest Qualification",
      sortable: true,
      filter: true,
    },
    { field: "secondHighestQualification", sortable: true, filter: true },
    { field: "currentOrganisation", sortable: true, filter: true },
    {
      field: "jobPostType",
      headerName: "Postion Applied For",
      sortable: true,
      filter: true,
    },
    { field: "contactNo", sortable: true, filter: true },
    { field: "emailId", sortable: true, filter: true },
    { field: "gender", sortable: true, filter: true },
    { field: "dob", headerName: "Date of Birth", sortable: true, filter: true },
    { field: "otherQualification", sortable: true, filter: true },
    { field: "currentLocation", sortable: true, filter: true },
    { field: "preferredLocation", sortable: true, filter: true },
    { field: "maritalStatus", sortable: true, filter: true },
    { field: "currentDependent", sortable: true, filter: true },
    { field: "familyBackground", sortable: true, filter: true },
    { field: "currency", sortable: true, filter: true },
    { field: "currentSalary", sortable: true, filter: true },
    { field: "expectedSalary", sortable: true, filter: true },
    { field: "noticePeriod", sortable: true, filter: true },
    { field: "earlierWokedWithUs", sortable: true, filter: true },
    {
      field: "currentOpeningSource",
      headerName: "Opening Source",
      sortable: true,
      filter: true,
    },
    {
      field: "currentOpeningSourceOther",
      headerName: "Opening Source (Others)",
      sortable: true,
      filter: true,
    },
    { field: "coverLetter", sortable: true, filter: true },
    {
      field: "appliedAt", // m/d/Y H:i:s am|pm
      headerName: "Applied Date",
      sortable: true,
      filter: true,
    },
  ];
};

const LinkComponent = (props) => {
  let $linkURL = "";
  let lastElm = props && props.value ? props.value.split(".").pop() : "";
  if (props && props.colDef && props.colDef.field) {
    $linkURL = `${process.env.PUBLIC_URL}/viewdetails/${props.value}/${lastElm}/${props.colDef.field}`;
  }
  return <a href={`${$linkURL}`}>{props.value}</a>;
};

const getBooleanValue = (cssSelector) => {
  return document.querySelector(cssSelector).checked === true;
};

export {
  base64Encode,
  base64Decode,
  tableHeadValue,
  LinkComponent,
  getBooleanValue,
};
