import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignOut from "../views/auth/SignOut";
import Index from "../views/jobs/Index";
import ViewDetails from "../views/jobs/ViewDetails";
import Dashboard from "../views/admin/Dashboard";
import JobPost from "../views/admin/JobPost";
import NotFound from "../views/errors/NotFound";
import SignIn from "../views/auth/SignIn";

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={`/`} element={<Index />} />
        <Route exact path={`/login`} element={<SignIn />} />
        <Route
          exact
          path={`/viewdetails/:key/:type/:field`}
          element={<ViewDetails />}
        />
        <Route exact path={`/logout`} element={<SignOut />} />
        <Route exact path={`/admin/dashboard`} element={<Dashboard />} />
        <Route exact path={`/admin/jobpost`} element={<JobPost />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
