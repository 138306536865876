import React from "react";
import { useFormikContext } from "formik";

export default function ReviewDetails() {
  const { values: formValues } = useFormikContext();

  let reviewData = JSON.parse(JSON.stringify(formValues)); //deep clone
  if (reviewData && reviewData.uploadFile && reviewData.uploadFile.name) {
    reviewData.uploadFile = reviewData.uploadFile.name;
  }
  if (
    reviewData &&
    reviewData.uploadOtherFile &&
    reviewData.uploadOtherFile.name
  ) {
    reviewData.uploadOtherFile = reviewData.uploadOtherFile.name;
  }
  if (reviewData && reviewData.jobPostType) {
    reviewData.jobPostType = reviewData.jobPostType.toString();
  }

  return (
    <React.Fragment>
      <h4>Review Details</h4> <hr />
      <h6 className="review-label">
        Please review and check all your information before submitting the job
        application.
      </h6>
      <table className="table table-hover table-responsive review-table">
        <thead>
          <tr>
            <th scope="col">Label</th>
            <th scope="col">Value</th>
          </tr>
        </thead>
        <tbody>
          {reviewData &&
            Object.keys(reviewData).map((element, index) => (
              <tr key={index}>
                <td>{element}</td>
                <td>{reviewData[element]}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
}
