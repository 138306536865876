import React, { Fragment, useEffect, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { isEmpty } from "lodash";
import AdminLayout from "../layouts/AdminLayout";
import { getHttp, postHttp } from "../../utilities/Actions";

export default function JobPost() {
  const [jobpost, setJobPost] = useState("");
  const [message, setMessage] = useState("");
  const [dataList, setDataList] = useState({});
  const [error, setError] = useState("");
  const gridRef = useRef(null);
  const [tableHead] = useState([
    {
      field: "id",
      sortable: true,
      filter: true,
      checkboxSelection: true,
    },
    {
      field: "post",
      headerName: "Post Name",
      sortable: true,
      filter: true,
      width: "450px",
    },
    {
      field: "creator",
      headerName: "Created by",
      sortable: true,
      filter: true,
      width: "200px",
    },
    {
      field: "status",
      sortable: true,
      filter: true,
      width: "100px",
    },
    {
      field: "status_admin",
      headerName: "Status (Admin)",
      sortable: true,
      filter: true,
      width: "150px",
    },
    {
      field: "updated_at",
      headerName: "Updated Date",
      sortable: true,
      filter: true,
      width: "200px",
    },
  ]);


  const postCreator = "Admin";

  const handleSubmit = (e) => {
    e.preventDefault();
    if (jobpost && jobpost.length > 0) {
      const payload = { post: jobpost, creator: postCreator };
      postHttp(`/post-create`, payload, {})
        .then((response) => {
          console.log("response", response);
          setJobPost("");
          setMessage(response.message);
        })
        .catch((error) => {
          console.log("error", error);
          setMessage("Something went wrong!");
        });
    } else {
      setMessage("Please enter a valid jobpost name!");
    }
  };

  // const handleDelete = (ids) => {
  //   if (ids && ids.length > 0) {
  //     getHttp(`/post-delete?ids=${ids}`)
  //       .then((response) => {
  //         setMessage(response.message);
  //       })
  //       .catch((error) => {
  //         console.log("error", error);
  //         setMessage("Something went wrong!");
  //       });
  //   }
  // };

  const handleStatus = (ids, status) => {
    if (ids && ids.length > 0) {
      getHttp(`/post-status?ids=${ids}&status=${status}`)
        .then((response) => {
          setMessage(response.message);
        })
        .catch((error) => {
          console.log("error", error);
          setMessage("Something went wrong!");
        });
    }
  };

  const handleStatusAdmin = (ids, status) => {
    if (ids && ids.length > 0) {
      getHttp(`/post-status-admin?ids=${ids}&status=${status}`)
        .then((response) => {
          setMessage(response.message);
        })
        .catch((error) => {
          console.log("error", error);
          setMessage("Something went wrong!");
        });
    }
  };

  useEffect(() => {
    getHttp("/post-list?status=all", {})
      .then((response) => {
        setError("");
        setDataList(response.resultList);
      })
      .catch((error) => {
        console.log("Error", error);
        setError(error);
      });
  }, [jobpost, message]);

  const handleButtonClick = (e, isFor = null) => {
    e.preventDefault();
    const nodes = gridRef.current.api.getSelectedNodes();
    const selectedData = nodes.map((node) => node.data);
    const nodeStrings = selectedData.map((node) => `${node.id}`).join(",");
    const selectedItems = nodeStrings ? nodeStrings : "";

    if (isEmpty(nodeStrings)) {
      alert("Please select the checkbox(s) to proceed further!");
      return false;
    } else {
      if (isFor && (isFor === "enable" || isFor === "disbale")) {
        if (
          window.confirm(`Are you sure to ${isFor} ${selectedItems} records?`)
        ) {
          handleStatus(nodeStrings, isFor);
        }
      }
    }
  };

  const handleButtonClickAdmin = (e, isFor = null) => {
    e.preventDefault();
    const nodes = gridRef.current.api.getSelectedNodes();
    const selectedData = nodes.map((node) => node.data);
    const nodeStrings = selectedData.map((node) => `${node.id}`).join(",");
    const selectedItems = nodeStrings ? nodeStrings : "";

    if (isEmpty(nodeStrings)) {
      alert("Please select the checkbox(s) to proceed further!");
      return false;
    } else {
      if (isFor && (isFor === "enable" || isFor === "disbale")) {
        if (
          window.confirm(`Are you sure to ${isFor} ${selectedItems} records?`)
        ) {
          handleStatusAdmin(nodeStrings, isFor);
        }
      }
    }
  };

  let renderJobTypesListTable;
  if (dataList && Object.keys(dataList).length > 0) {
    renderJobTypesListTable = (
      <Fragment>
        <div className="jobpostlist">
          <div className="row mb-3">
            <div className="col-6">

              <button
                onClick={(e) => handleButtonClick(e, "enable")}
                className="btn btn-success btn-sm mb-3"
              >
                Enable Record(s)
              </button>

              <button
                onClick={(e) => handleButtonClick(e, "disbale")}
                className="btn btn-danger btn-sm mb-3 mx-3"
              >
                Disable Record(s)
              </button>
              <br />
              <span className="text-success">(Website - Job Application Form)</span>
            </div>
            <div className="col-6">
              <button
                onClick={(e) => handleButtonClickAdmin(e, "enable")}
                className="btn btn-success btn-sm mb-3"
              >
                Enable Record(s)
              </button>

              <button
                onClick={(e) => handleButtonClickAdmin(e, "disbale")}
                className="btn btn-danger btn-sm mb-3 mx-3"
              >
                Disable Record(s)
              </button>
              <br />
              <span className="text-danger">(Admin Status - Job Applications DropDown Filters)</span>
            </div>
          </div>
          <div
            className="ag-theme-alpine"
            style={{ height: 550, width: "100%" }}
          >
            <AgGridReact
              rowData={dataList}
              columnDefs={tableHead}
              ref={gridRef}
              rowSelection="multiple"
              pagination={true}
              paginationPageSize={10}
            ></AgGridReact>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <AdminLayout>
        <div className="row">
          <h6>Add New Job Post</h6>
          <div className="col">
            <form className="row g-3" method="post">
              <div className="col-4">
                <input
                  name="jobpost"
                  type="text"
                  value={jobpost}
                  onChange={(e) => setJobPost(e.target.value)}
                  className="form-control input-md"
                  placeholder="Enter job post name"
                />
              </div>
              <div className="col-auto">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="btn btn-primary mb-3"
                >
                  Save
                </button>
                <span className="px-3 small text-danger">{message}</span>
              </div>
            </form>
          </div>
        </div>
        <hr />
        {error ?? ""}
        {renderJobTypesListTable}
      </AdminLayout>
    </Fragment>
  );
}
