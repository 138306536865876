import React, { Fragment } from "react";

export default function Steps(props) {
  return (
    <Fragment>
      <div className="stepper">
        <ul className="progressbar">
          {props &&
            props.steps &&
            props.steps.map((element, index) => (
              <li
                key={index}
                className={
                  +index === props.activeStep || +index < props.activeStep
                    ? "active"
                    : ""
                }
              >
                {element}
              </li>
            ))}
        </ul>
      </div>
    </Fragment>
  );
}
