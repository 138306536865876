import React from "react";
import JobsLayout from "../layouts/JobsLayout";
import ApplicationForm from "../jobs/ApplicationForm";

export default function Index() {
  return (
    <JobsLayout>
      <ApplicationForm />
    </JobsLayout>
  );
}
