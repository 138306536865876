import React, { Fragment, useState, useEffect } from "react";
import {
  InputField,
  DatePickerField,
  CheckboxField,
  TextAreaField,
} from "../formFields";
import axios from "axios";
import Bootstrap from "../../../configs/Bootstrap";

export default function PersonalDetails(props) {
  const {
    formField: {
      jobPostType,
      fullName,
      gender,
      contactNo,
      emailId,
      dob,
      maritalStatus,
      familyBackground,
    },
  } = props;

  const [dataList, setDataList] = useState([]);
  useEffect(() => {
    let reqParams = {
      headers: { "content-type": "appilication/json" },
      params: {},
    };
    axios
      .get(`${Bootstrap.JOB_API_BASE_URL}/post-list`, reqParams)
      .then((response) => {
        setDataList(response.data.resultList);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, []);

  return (
    <React.Fragment>
      <h4>Personal Details</h4> <hr />
      <div className="row mb-3">
        <div className="col">
          <label className="form-check-label">{jobPostType.label}</label>
          <div className="form-check">
            {dataList &&
              dataList.map((element, index) => (
                <Fragment key={index}>
                  <CheckboxField
                    name={jobPostType.name}
                    value={`${element.post}-${element.id}`}
                    label={element.post}
                  />
                </Fragment>
              ))}
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <InputField
            name={fullName.name}
            label={fullName.label}
            placeholder={fullName.label}
          />
        </div>
        <div className="col">
          <InputField
            name={emailId.name}
            label={emailId.label}
            placeholder={emailId.label}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <InputField
            name={gender.name}
            label={gender.label}
            placeholder={gender.label}
          />
        </div>
        <div className="col">
          <InputField
            name={contactNo.name}
            label={contactNo.label}
            placeholder={contactNo.label}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <DatePickerField
            name={dob.name}
            label={dob.label}
            placeholder={dob.label}
          />
        </div>
        <div className="col">
          <InputField
            name={maritalStatus.name}
            label={maritalStatus.label}
            placeholder={maritalStatus.label}
          />
        </div>
      </div>
      <div className="row mb-3">
        <TextAreaField
          name={familyBackground.name}
          label={familyBackground.label}
          placeholder={familyBackground.label}
        />
      </div>
    </React.Fragment>
  );
}
