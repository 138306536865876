import React, { Fragment } from "react";

export default function NotFound() {

  return (
    <Fragment>
      <div className="container content">
        <div style={{ textAlign: "center", margin: "50px" }}>
          <h1>404 - Not Found!</h1>
          <p>Woops! it's 404, The page you are looking doesn't exits!</p>
          <a href={`${process.env.PUBLIC_URL}`}>Go Home</a>
        </div>
      </div>
    </Fragment>
  );
}
