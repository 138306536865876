let HOSTNAME;
try {
  HOSTNAME = window.location.hostname;
} catch (e) {}

const Bootstrap = {};
if (HOSTNAME && HOSTNAME === "localhost") {
  Bootstrap.IS_DEVELOPMENT = true;
  Bootstrap.APP_BASE_URL = "http://localhost:3000";
  Bootstrap.API_BASE_URL = "http://localhost:3000";
  Bootstrap.JOB_API_BASE_URL = "https://www.cseindia.org/jobs-dev";
} else {
  Bootstrap.IS_DEVELOPMENT = false;
  Bootstrap.APP_BASE_URL = "https://jobs.cseindia.org";
  Bootstrap.API_BASE_URL = "https://www.cseindia.org";
  Bootstrap.JOB_API_BASE_URL = "https://www.cseindia.org/jobs";
}

Bootstrap.VERSION = 1;
Bootstrap.ERROR_TYPE = "MISSING_DATA";
Bootstrap.ERROR_MESSAGE = "Something went wrong, please try later!";
Bootstrap.NO_DATA_FOUND = "No data found!";
Bootstrap.GOOGLE_SECRET_KEY =
  "381209346494-vk09r96uelm2acnr694ilqbp9b28egqk.apps.googleusercontent.com";
Bootstrap.NEWSLETTER_URL =
  "https://cseindia.us9.list-manage.com/subscribe?u=83d0f10776ab17936852f643a&amp;id=b0f7a24a54";

export default Bootstrap;

// const Bootstrap = {
//   APP_BASE_URL: "https://jobs.cseindia.org",
//   API_BASE_URL: "https://www.cseindia.org",
//   JOB_API_BASE_URL: "https://www.cseindia.org/jobs",
//   VERSION: 1,
//   ERROR_TYPE: "MISSING_DATA",
//   ERROR_MESSAGE: "Something went wrong, please try later!",
//   NO_DATA_FOUND: "No data found!",
//   GOOGLE_SECRET_KEY:
//     "381209346494-vk09r96uelm2acnr694ilqbp9b28egqk.apps.googleusercontent.com",
// };

// export default Bootstrap;
